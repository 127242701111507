.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.row-item-list .row-item {
  position: relative;
  width: 100%;
  min-height: 2.2rem;
  padding-top: 0.24rem;
  padding-left: 2.4rem;
  background-color: #fff;
  box-shadow: 0px 0.06rem 0.4rem 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem;
  overflow: hidden;
  z-index: 1;
}
.row-item-list .row-item .img-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 2.2rem;
  height: 100%;
  background-color: #faf8fb;
  z-index: 2;
}
.row-item-list .row-item .img-box .img {
  width: 100%;
  height: 100%;
}
.row-item-list .row-item .sales {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0.1rem 0;
  padding-right: 0.1rem;
}
.row-item-list .row-item .item-name {
  max-width: 100%;
  font-size: 0.28rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 0.4rem;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
.row-item-list .row-item .item-name .sourceName {
  position: relative;
  top: -0.02rem;
  display: inline-block;
  height: 0.36rem;
  line-height: 0.36rem;
  padding: 0 0.15rem;
  border-radius: 0.18rem;
  background-color: var(--main-color);
  color: #ffffff;
  font-size: 0.18rem;
}
.row-item-list .row-item .item-intro {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 0.24rem;
  color: #999;
  height: 0.65rem;
  line-height: 0.34rem;
}
.row-item-list .row-item .priceWithBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.1rem;
}
.row-item-list .row-item .priceWithBtn .item-price {
  /* position: absolute;
		  left: 2.4rem;
		  bottom: 0.2rem; */
}
.row-item-list .row-item .priceWithBtn .item-price .original-price {
  color: #919193 !important;
  font-size: 0.16rem;
}
.row-item-list .row-item .priceWithBtn .item-price .price {
  color: #333;
  line-height: 0.28rem;
  font-weight: bold;
}
.row-item-list .row-item .priceWithBtn .item-price .price .sign {
  font-size: 0.24rem;
}
.row-item-list .row-item .priceWithBtn .item-price .price .num {
  font-size: 0.32rem;
}
.row-item-list .row-item .priceWithBtn .item-price .old-price {
  display: inline-block;
  margin-left: 0.08rem;
  font-size: 0.16rem;
  color: rgba(0, 0, 0, 0.38);
  line-height: 0.2rem;
  font-weight: bold;
  text-decoration: line-through;
}
.row-item-list .row-item .priceWithBtn .buy-btn {
  /* position: absolute;
		  right: 0;
		  bottom: 0.2rem; */
  width: 0.9rem;
  height: 0.48rem;
  padding-left: 0.26rem;
  padding-top: 0.08rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.34rem;
  font-weight: 500;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 2rem 0px 0px 2rem;
  z-index: 2;
}
.row-item-list .row-item .lusse {
  font-size: 0.2rem;
  margin-bottom: 0.1rem;
  color: #000000;
}
.row-item-list .row-item .lusse .plans {
  color: #fe5d1c;
}
.row-item-list .row-item .lusse .money {
  font-size: 0.4rem;
}
.row-item-list .row-item .zjg-max {
  color: var(--main-color);
  font-size: 0.2rem;
  margin-bottom: 0.1rem;
}
.row-item-list .row-item .group-num-wrap {
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  display: flex;
  align-items: center;
  z-index: 2;
}
.row-item-list .row-item .group-num-wrap .group-num {
  font-size: 0.22rem;
  color: #fff;
  line-height: 0.32rem;
  white-space: nowrap;
}
.row-item-list .row-item + .row-item {
  margin-top: 0.3rem;
}
