.border-none[data-v-00052f0a]:before {
  display: none;
}
.border-none[data-v-00052f0a]:after {
  display: none;
}
.flex-fix[data-v-00052f0a] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-00052f0a]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-00052f0a] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-00052f0a] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-00052f0a] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-00052f0a] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-00052f0a] {
  *zoom: 1;
}
.clear-fix[data-v-00052f0a]:before,
.clear-fix[data-v-00052f0a]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#search[data-v-00052f0a] {
  width: 100%;
  height: 1rem;
  padding: 0 0.3rem;
}
