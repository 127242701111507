.border-none[data-v-916f4fd2]:before {
  display: none;
}
.border-none[data-v-916f4fd2]:after {
  display: none;
}
.flex-fix[data-v-916f4fd2] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-916f4fd2]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-916f4fd2] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-916f4fd2] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-916f4fd2] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-916f4fd2] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-916f4fd2] {
  *zoom: 1;
}
.clear-fix[data-v-916f4fd2]:before,
.clear-fix[data-v-916f4fd2]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.row-item-list .row-item[data-v-916f4fd2] {
  position: relative;
  width: 100%;
  min-height: 2.2rem;
  padding-top: 0.24rem;
  padding-left: 2.4rem;
  background-color: #fff;
  -webkit-box-shadow: 0px 0.06rem 0.4rem 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0.06rem 0.4rem 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem;
  overflow: hidden;
  z-index: 1;
}
.row-item-list .row-item .img-box[data-v-916f4fd2] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 2.2rem;
  height: 100%;
  background-color: #faf8fb;
  z-index: 2;
}
.row-item-list .row-item .img-box .img[data-v-916f4fd2] {
  width: 100%;
  height: 100%;
}
.row-item-list .row-item .sales[data-v-916f4fd2] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0.1rem 0;
  padding-right: 0.1rem;
}
.row-item-list .row-item .item-name[data-v-916f4fd2] {
  max-width: 100%;
  font-size: 0.28rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 0.4rem;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
.row-item-list .row-item .item-name .sourceName[data-v-916f4fd2] {
  position: relative;
  top: -0.02rem;
  display: inline-block;
  height: 0.36rem;
  line-height: 0.36rem;
  padding: 0 0.15rem;
  border-radius: 0.18rem;
  background-color: var(--main-color);
  color: #ffffff;
  font-size: 0.18rem;
}
.row-item-list .row-item .item-intro[data-v-916f4fd2] {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 0.24rem;
  color: #999;
  height: 0.65rem;
  line-height: 0.34rem;
}
.row-item-list .row-item .priceWithBtn[data-v-916f4fd2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 0.1rem;
}
.row-item-list .row-item .priceWithBtn .item-price[data-v-916f4fd2] {
  /* position: absolute;
		  left: 2.4rem;
		  bottom: 0.2rem; */
}
.row-item-list .row-item .priceWithBtn .item-price .original-price[data-v-916f4fd2] {
  color: #919193 !important;
  font-size: 0.16rem;
}
.row-item-list .row-item .priceWithBtn .item-price .price[data-v-916f4fd2] {
  color: #333;
  line-height: 0.28rem;
  font-weight: bold;
}
.row-item-list .row-item .priceWithBtn .item-price .price .sign[data-v-916f4fd2] {
  font-size: 0.24rem;
}
.row-item-list .row-item .priceWithBtn .item-price .price .num[data-v-916f4fd2] {
  font-size: 0.32rem;
}
.row-item-list .row-item .priceWithBtn .item-price .old-price[data-v-916f4fd2] {
  display: inline-block;
  margin-left: 0.08rem;
  font-size: 0.16rem;
  color: rgba(0, 0, 0, 0.38);
  line-height: 0.2rem;
  font-weight: bold;
  text-decoration: line-through;
}
.row-item-list .row-item .priceWithBtn .buy-btn[data-v-916f4fd2] {
  /* position: absolute;
		  right: 0;
		  bottom: 0.2rem; */
  width: 0.9rem;
  height: 0.48rem;
  padding-left: 0.26rem;
  padding-top: 0.08rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.34rem;
  font-weight: 500;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 2rem 0px 0px 2rem;
  z-index: 2;
}
.row-item-list .row-item .lusse[data-v-916f4fd2] {
  font-size: 0.2rem;
  margin-bottom: 0.1rem;
  color: #000000;
}
.row-item-list .row-item .lusse .plans[data-v-916f4fd2] {
  color: #fe5d1c;
}
.row-item-list .row-item .lusse .money[data-v-916f4fd2] {
  font-size: 0.4rem;
}
.row-item-list .row-item .zjg-max[data-v-916f4fd2] {
  color: var(--main-color);
  font-size: 0.2rem;
  margin-bottom: 0.1rem;
}
.row-item-list .row-item .group-num-wrap[data-v-916f4fd2] {
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 2;
}
.row-item-list .row-item .group-num-wrap .group-num[data-v-916f4fd2] {
  font-size: 0.22rem;
  color: #fff;
  line-height: 0.32rem;
  white-space: nowrap;
}
.row-item-list .row-item + .row-item[data-v-916f4fd2] {
  margin-top: 0.3rem;
}
